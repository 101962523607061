import { axiosInstance } from "@api/index";
import { useSelector } from "react-redux";

export const useFetchPlans = () => {
  const activePlan = useSelector((state) => state.plans.activePlan);

  const initiatePayment = async ({ paymentMethod, paymentProvider }) => {
    console.log("payment method: ", paymentMethod);
    try {
      const res = await axiosInstance.post("/me/subscriptions/initiate", {
        subscriptionPlanId: activePlan.id,
        paymentProvider,
        paymentMethod,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  const activateSubscription = async ({
    paymentMethod,
    paymentProvider,
    paymentMethodId,
  }) => {
    try {
      const res = await axiosInstance.post("/me/subscriptions/activate", {
        paymentMethod,
        paymentProvider,
        subscriptionPlanId: activePlan.id,
        stripe: {
          paymentMethodId,
        },
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmitPlanPaddle = async () => {
    try {
      const response = await axiosInstance.post("/me/subscriptions/initiate", {
        paymentMethod: "CREDIT_CARD",
        paymentProvider: "PADDLE",
        subscriptionPlanId: activePlan.id,
      });

      console.log(response.data);

      return response.data;
    } catch (error) {
      console.error("Error creating Paddle transaction:", error);
      throw error;
    }
  };

  const handleSubmitPlanStripe = async () => {
    try {
      const response = await axiosInstance.post(
        `/me/subscriptions?subscriptionPlanId=${activePlan.id}&paymentProvider=STRIPE`
      );

      const { stripe } = response.data;
      return stripe;
    } catch (error) {
      console.error("Error creating Paddle transaction:", error);
      throw error;
    }
  };

  return {
    activePlan,
    handleSubmitPlanPaddle,
    handleSubmitPlanStripe,
    initiatePayment,
    activateSubscription,
  };
};
