import useDiscountCountdown from "@hooks/useDiscountCountdown";
import styles from "./styles.module.scss";
import { Clock } from "@assets/Icons";

export const CountdownTimer = () => {
  const { time } = useDiscountCountdown(5 * 60 * 1000);

  return (
    <div className={styles.countdownTimer}>
      <Clock />
      <p>
        THIS OFFER ENDS IN <span>{time.displayMinutes}</span>:
        <span>{time.displaySeconds}</span>
      </p>
    </div>
  );
};
