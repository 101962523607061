import { initializeApp } from "firebase/app";
import { getRemoteConfig } from "firebase/remote-config";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC2BpCaXyjp1vwn4Jy_AYXSNc2djK7PhfQ",
  authDomain: "easyfit-web.firebaseapp.com",
  projectId: "easyfit-web",
  storageBucket: "easyfit-web.firebasestorage.app",
  messagingSenderId: "517213540448",
  appId: "1:517213540448:web:e0657d2b2a69e838767305",
  measurementId: "G-M8MXL6MYVX",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const remoteConfig = getRemoteConfig(app);
remoteConfig.settings = {
  minimumFetchIntervalMillis: 43200000,
};

export { remoteConfig };
