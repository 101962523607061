import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Header } from "./components/header";
import { Stepper } from "./components/stepper/index";
import { CountdownTimer } from "./components/timer";
import { Title } from "./components/title";
import { useDispatch, useSelector } from "react-redux";
import { getPlansThunk, setActivePlan } from "src/redux/slices/plans";
import { getAgreementTextUpsale } from "../paywall/components/plan/utils/getPlanDetails";
import { useNavigate } from "react-router";
import { v4 as uuid } from "uuid";
import { Plan } from "./components/plan";
import { getUserInfoThunk } from "src/redux/slices/userSlice";
import { DiscountProvider } from "../paywall/DiscountContext";

export const Upsale = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserInfoThunk());
  }, []);

  const { activePlan } = useSelector((state) => state.plans);

  const [idempotencyKey, setIdempotencyKey] = useState("");
  console.log(idempotencyKey);

  useEffect(() => {
    setIdempotencyKey(uuid());
    dispatch(getPlansThunk());
  }, []);

  const handleSkip = () => {
    navigate("/activate");
  };

  const agreementText = getAgreementTextUpsale(activePlan);

  return (
    <DiscountProvider>
      <Header onSkip={handleSkip} />
      <div className={styles.layout}>
        <Stepper />
        <Title />
        <CountdownTimer />
        <Plan idempotencyKey={idempotencyKey} onSkip={handleSkip} />
        <p className={styles.note}>{agreementText}</p>
      </div>
    </DiscountProvider>
  );
};
