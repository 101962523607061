import { Link } from "react-router-dom";

import styles from "./styles.module.scss";
import { DATA } from "./data";
import { useEffect } from "react";
import nextImage from "../peopleChosen/img/bg.jpg";
import authService from "@api/services/authService";
import { updateDataLayer } from "@utils/updateDataLayer";

export const StartJourney = ({ onNextStep }) => {
  useEffect(() => {
    const startAuth = async () => {
      await authService.anonymousSignUp();
 
    };
    if (!localStorage.getItem("token")) startAuth();
    updateDataLayer({ 
      event: 'FirstQuestionShown',
      userId: localStorage.getItem("userId"),
    });
    const img = new Image();
    img.src = nextImage;
  }, []);

  const handleNextStep = (value) => {
    onNextStep(value);
  };
  return (
    <div className={styles.container} id="breakdown">
      <h1>Start your fitness journey</h1>
      <p className={styles.choose}>Choose your age bracket</p>
      <p className={styles.quiz}>1-min quiz</p>
      <div className={styles.cards}>
        {DATA.map((item) => (
          <div
            key={item.value}
            onClick={() => handleNextStep(item.value)}
            className={styles.card}
          >
            <img src={item.image} alt={item.value} />
            <div className={styles.label}>Age: {item.value}</div>
          </div>
        ))}
      </div>
      <p className={styles.privacy}>
        By choosing your age and continuing you agree to our{" "}
        <Link to="/terms-of-use">Terms of Service</Link> |
        <Link to="/privacy-policy"> Privacy Policy</Link>.<br />
        Please review before continuing.
      </p>
    </div>
  );
};
