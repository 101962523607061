import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { initializePaddle } from "@paddle/paddle-js";
import { useFetchPlans } from "../hooks/useFetchPlans";
import styles from "./styles.module.scss";
import { successToast } from "@utils/toaster";
import paypal from "./PayPal.png";
import { updateDataLayer } from "@utils/updateDataLayer";

let currentSubscriptionId;

const PaddleCheckout = ({ id }) => {
  const [paddle, setPaddle] = useState();
  const location = useLocation();
  const { handleSubmitPlanPaddle, activePlan } = useFetchPlans();
  const navigate = useNavigate();

  useEffect(() => {
    initializePaddle({
      checkout: {
        settings: {
          allowedPaymentMethods: ["paypal"],
          displayMode: "inline",
          variant: "one-page",
          theme: "light",
          locale: "en",
          frameTarget: "checkout-container",
          frameInitialHeight: "450",
          frameStyle:
            "width: 100%; min-width: 312px; background-color: transparent; border: none;",
        },
      },
      environment: process.env.REACT_APP_PADDLE_ENV,
      token: process.env.REACT_APP_PADDLE_TOKEN,
      eventCallback: function (data) {
        if (data.name === "checkout.completed") {
          setTimeout(() => {
            paddle?.Checkout.close();
            successToast({ message: "Payment successful! Thank you!" });

            const userId = localStorage.getItem("userId");

            updateDataLayer({
              event: "Purchase",
              paymentMethod: "PAYPAL",
              plan: activePlan.title,
              currency: activePlan.currency,
              price: +activePlan.priceAmount / 100,
              userId,
              subscriptionId: currentSubscriptionId,
            });

            if (
            
              location.pathname !== "/upsale"
            ) {
              navigate("/upsale");
            } else {
              navigate("/activate");
            }
          }, 5000);
        }
      },
    }).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, [paddle]);

  const handleOpenPaddleCheckout = async () => {
    const { subscriptionId, paddleTransactionId: transactionId } =
      await handleSubmitPlanPaddle();
    currentSubscriptionId = subscriptionId;
    paddle?.Checkout.open({
      transactionId,
    });
  };

  return (
    <>
      <button
        className={`${styles.paypal} ${id}`}
        onClick={handleOpenPaddleCheckout}
      >
        <img id={id} src={paypal} alt="pp" />
      </button>
      <div id="checkout-container" className="checkout-container"></div>
    </>
  );
};

export default PaddleCheckout;
