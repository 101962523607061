import {
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { useFetchPlans } from "../hooks/useFetchPlans";
import { useNavigate } from "react-router";
import { errorToast, successToast } from "@utils/toaster";
import styles from "./styles.module.scss";
import { useSelector } from "react-redux";
import { axiosInstance } from "@api/index";
import { usePaymentButtonMethod } from "../hooks/usePaymentMethod";
import { updateDataLayer } from "@utils/updateDataLayer";

export const PaymentButton = ({ id }) => {
  const { paymentMethod } = usePaymentButtonMethod();

  console.log("paymentMethod", paymentMethod);
  console.log("id", id);

  const user = useSelector((state) => state.user.userInfo);
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const { initiatePayment, activePlan } = useFetchPlans();

  let currentSubscriptionId;

  const onConfirm = async () => {
    if (!stripe) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      errorToast({ message: submitError.message });
      return;
    }

    const getStripeClientSecret = async () => {
      try {
        const { stripeClientSecret, subscriptionId } = await initiatePayment({
          paymentMethod: paymentMethod,
          paymentProvider: "STRIPE",
        });
        currentSubscriptionId = subscriptionId;
        return stripeClientSecret;
      } catch (error) {
        errorToast({ message: error.response.data.message });
      }
    };

    const clientSecret = await getStripeClientSecret();

    const payload = await stripe.confirmPayment({
      elements,
      clientSecret,
      redirect: "if_required",
      confirmParams: {
        return_url: "https://easyfit.me",
      },
    });

    if (payload.error) {
      errorToast({ message: payload.error.message });
    } else {
      await axiosInstance.post("/auth/send-pin-to-email", {
        email: user?.email,
      });

      const userId = localStorage.getItem("userId");

      updateDataLayer({
        event: "Purchase",
        paymentMethod: paymentMethod,
        plan: activePlan.title,
        currency: activePlan.currency,
        price: +activePlan.priceAmount / 100,
        userId,
        subscriptionId: currentSubscriptionId,
      });

        navigate("/upsale");
     
      successToast({ message: "Payment successful! Thank you!" });
    }
  };

  return (
    <>
      <ExpressCheckoutElement
        className={`${styles.payButton} ${id}`}
        onConfirm={onConfirm}
        data-testid="express-checkout-element"
      />
    </>
  );
};
