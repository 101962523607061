import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../../api";

const initialState = {
  somaticPlans: [],
  somaticRetentionPlans: [],
  easyfitPlans: [],
  easyfitRetentionPlans: [],
  upsalePlans: [],
  activePlan: null,
  isLoading: false,
};

export const getPlansThunk = createAsyncThunk("plans/getPlans", async () => {
  try {
    const res = await axiosInstance.get("/subscription-plans");

    const { items } = res.data;

    const somaticPlans = items.filter(
      (plan) => plan.products.includes("SOMATIC") && plan.offer === "DEFAULT"
    );

    const easyfitPlans = items.filter(
      (plan) => plan.products.includes("EASYFIT") && plan.offer === "DEFAULT"
    );

    const easyfitRetentionPlans = items.filter(
      (plan) => plan.products.includes("EASYFIT") && plan.offer === "RETENTION"
    );

    const upsalePlans = items.filter(
      (plan) =>
        plan.products.includes("SIX_PACK") || plan.products.includes("KEGEL")
    );

    const somaticRetentionPlans = items.filter(
      (plan) => plan.products.includes("SOMATIC") && plan.offer === "RETENTION"
    );

    return {
      easyfitPlans,
      easyfitRetentionPlans,
      upsalePlans,
      somaticPlans,
      somaticRetentionPlans,
    };
  } catch (error) {
    console.log(error);
  }
});

const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    setActivePlan(state, { payload }) {
      state.activePlan = payload;
    },
    resetActivePlan(state) {
      state.activePlan = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlansThunk.pending, (state, { payload }) => {
        state.isLoading = true;
      })
      .addCase(getPlansThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.easyfitPlans = payload.easyfitPlans;
          state.easyfitRetentionPlans = payload.easyfitRetentionPlans;
          state.somaticPlans = payload.somaticPlans;
          state.somaticRetentionPlans = payload.somaticRetentionPlans;
          state.upsalePlans = payload.upsalePlans;
        }
        state.isLoading = false;
      });
  },
});

export const reducer = plansSlice.reducer;

export const { reset, setActivePlan, resetActivePlan } = plansSlice.actions;
