import { axiosInstance } from "../index";

const anonymousSignUp = async () => {
  return axiosInstance.post("auth/anonymous-sign-up", {}).then((response) => {
    if (response.data.token) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("userId", response.data.userId);
    }
    return response;
  });
};
const emailOnlySignUp = async (email) => {
  return axiosInstance
    .post("auth/email-only-sign-up", {
      email,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userId", response?.data?.userId);
      }
      return response;
    });
};

const emailAndPinSignIn = async (email, pin) => {
  try {
    const response = await axiosInstance.post("auth/email-and-pin-sign-in", {
      email,
      pin,
    });
    return { data: response.data, error: undefined };
  } catch (error) {
    console.log(error);
    return { data: undefined, error };
  }
};

const sendPinToEmail = async (email) => {
  try {
    const response = await axiosInstance.post("auth/send-pin-to-email", {
      email,
    });
    return { data: response.data, error: undefined };
  } catch (error) {
    return { data: undefined, error };
  }
};

const logout = () => {
  localStorage.removeItem("token");
};

const getCurrentUser = () => {
  return localStorage.getItem("token");
};

const getInfo = async () => {
  return axiosInstance.get("/me").then((response) => {
    return response;
  });
};

const authService = {
  anonymousSignUp,
  sendPinToEmail,
  emailOnlySignUp,
  emailAndPinSignIn,
  logout,
  getCurrentUser,
  getInfo,
};

export default authService;
