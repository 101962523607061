import { useState } from "react";
import styles from "./styles.module.scss";
import { Loader } from "@common/loader";
import { Button } from "@common/button";
import { errorToast, successToast } from "@utils/toaster";
import { useNavigate } from "react-router";
import { useStripe } from "@stripe/react-stripe-js";
import { axiosInstance } from "@api/index";
import { CustomCheckoutModal } from "src/routes/onboarding/shared/components/paywall/components/plan/components/customCheckoutModal";
import { updateDataLayer } from "@utils/updateDataLayer";

export const PaymentOptions = ({ activePlan, idempotencyKey, onSkip }) => {
  const stripe = useStripe();
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handlePurchaseSuccess = (subscriptionId) => {
    const userId = localStorage.getItem("userId");
    updateDataLayer({
      event: "Purchase",
      paymentMethod: "CREDIT_CARD",
      plan: activePlan.title,
      currency: activePlan.currency,
      price: +activePlan.priceAmount / 100,
      userId,
      subscriptionId,
    });
    successToast({ message: "Payment successful! Thank you!" });
    navigate("/activate");
  };

  const handleQuickPurchase = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.post(
        "/me/subscriptions/quick-purchase",
        {
          subscriptionPlanId: activePlan.id,
          idempotencyKey,
        }
      );
      const {
        available,
        requiresConfirmation,
        confirmationStripeClientSecret,
        subscriptionId,
      } = response.data;

      if (!available) {
        setModalOpen(true);
        return;
      }
      if (requiresConfirmation) {
        await handle3DSecureConfirmation(confirmationStripeClientSecret, subscriptionId);
      } else {
        handlePurchaseSuccess(subscriptionId);
      }
    } catch (e) {
      if (
        e.response?.status === 402 ||
        e.response?.data?.code === "QUICK_PAYMENT_NOT_AVAILABLE"
      ) {
        setModalOpen(true);
        errorToast({ message: e.response.data.message });
      }
      errorToast({ message: e.response.data.message });
    } finally {
      setLoading(false);
    }
  };

  const handle3DSecureConfirmation = async (clientSecret, subscriptionId) => {
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret
    );
    if (error) {
      errorToast({ message: error.message });
    } else if (paymentIntent.status === "succeeded") {
      handlePurchaseSuccess(subscriptionId);
    } else {
      errorToast({ message: "Payment could not be confirmed" });
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <div className={styles.getPlanButton}>
        {loading ? (
          <Loader />
        ) : (
          <Button onClick={handleQuickPurchase} disabled={loading}>
            GET MY PLAN
          </Button>
        )}
      </div>

      <button className={styles.skipButton} onClick={onSkip}>
        SKIP THIS AND CONTINUE ENROLLMENT
      </button>
      {isModalOpen && (
        <CustomCheckoutModal onClose={closeModal} activePlan={activePlan} />
      )}
    </>
  );
};
