import { axiosInstance } from "..";

export const WorkoutContentService = {
  getUserSubscriptions: async () => {
    const response = await axiosInstance.get("/me/subscriptions/active");
    const products = response.data.reduce((acc, subscription) => {
      return [...acc, ...subscription.subscriptionPlan.products];
    }, []);
    return products || [];
  },
  get: (product) => {
    return axiosInstance.get(`/content/workouts?product=${product}`);
  },
};
