import { Link } from "react-router-dom";

import styles from "./styles.module.scss";
import { DATA } from "./data";
import { useEffect } from "react";
import nextImage1 from "../peopleChosen/img/18-29.png";
import nextImage2 from "../peopleChosen/img/40-49.png";
import nextImage3 from "../peopleChosen/img/50+.png";

import authService from "@api/services/authService";
import { KeyboardArrow } from "@assets/Icons";
import { usePreloadImages } from "@hooks/usePreloadImages";
import { updateDataLayer } from "@utils/updateDataLayer";

export const Initiate = ({ onNextStep }) => {
  useEffect(() => {
    const startAuth = async () => {
      await authService.anonymousSignUp();
    };
    if (!localStorage.getItem("token")) startAuth();
    updateDataLayer({ 
      event: 'FirstQuestionShown',
      userId: localStorage.getItem('userId')
    });
  }, []);

  usePreloadImages([nextImage1, nextImage2, nextImage3]);

  const handleNextStep = (value) => {
    localStorage.setItem("ageStart", value);
    onNextStep?.(value);
  };
  return (
    <div className={styles.container} id="breakdown">
      <h1>Somatic exercises</h1>
      <p className={styles.release}>
        Release emotions, reduce tensions and lose weight
      </p>
      <p className={styles.quiz}>1-MIN QUIZ</p>
      <div className={styles.cards}>
        {DATA.map((item) => (
          <div
            key={item.value}
            onClick={() => handleNextStep(item.value)}
            className={styles.card}
          >
            <img src={item.image} alt={item.value} />
            <div className={styles.label}>
              Age: {item.value} <KeyboardArrow />
            </div>
          </div>
        ))}
      </div>
      <p className={styles.privacy}>
        By choosing your age and continuing you agree to our{" "}
        <Link to="/terms-of-use">Terms of Service</Link> |
        <Link to="/privacy-policy"> Privacy Policy</Link>.<br />
        Please review before continuing.
      </p>
    </div>
  );
};
