import "./App.css";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { AuthPage } from "./routes/auth";
import ProtectedRoutes from "./api/ProtectedRoutes";
import DayExercises from "./routes/dayExercises";
import SomethingWentWrong from "./routes/somethingWentWrong/SomethingWentWrong";
import { useEffect } from "react";
import { LandingPage } from "./routes/landing";
import { PrivacyPolicy, SubscriptionPolicy, TermsOfUse } from "./routes/terms";
import {
  CurrentStep,
  OnboardingStepper,
  OnboardingSomaticStepper,
  CurrentSomaticStep,
} from "./routes/onboarding";
import { DATA } from "./routes/onboarding/default/stepper/questions/start/data";
import { usePreloadImages } from "./hooks/usePreloadImages";
import { Workouts } from "./routes/profile/workouts";
import { Workout } from "./routes/profile/workout";
import { Paywall } from "./routes/onboarding/default/paywall";
import { UserActivation } from "./routes/activate";
import { ThankyouPage } from "./routes/thankyoupage";
import { Portal } from "./routes/auth/Portal";

import {
  CreatingPlan,
  Name,
  Email,
  PlanChart,
} from "./routes/onboarding/default/finalQuestions";

import {
  PreRegistration,
  Email as EmailSomtic,
  Name as NameSomatic,
  Prediction as PredictionSomatic,
  PlanChart as PlanChartSomatic,
  CreatingPlan as CreatingPlanSomatic,
  Confident,
  Newsletter,
  FinalQuestionsWrapper,
} from "./routes/onboarding/somatic/finalQuestions";
import { SomaticPaywall } from "./routes/onboarding/somatic/paywall";
import { Profile } from "./routes/profile/activeWorkout";
import { RoutesWithEmail } from "./api/RoutesWithEmail";
import { Upsale } from "./routes/onboarding/shared/components/upsale";

function App() {
  usePreloadImages(DATA.map((item) => item.image));

  const removeLoader = () => {
    const loader = document.querySelector("#loader");
    if (loader) {
      loader.style.display = "none";
    }
  };
  useEffect(() => {
    if (document.readyState === "complete") {
      removeLoader();
    } else {
      window.addEventListener("load", removeLoader);
    }
  }, []);

  useEffect(() => {
    if (isSomatic) {
      console.log("Somatic web: 1.2");
    } else {
      console.log("Default web: 1.3");
    }
  }, []);

  const isSomatic = process.env.REACT_APP_PRODUCT === "SOMATIC";

  console.log(process.env.REACT_APP_PRODUCT);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route element={<Portal />} path="/portal" />
          <Route element={<AuthPage />} path="/auth" />
          <Route element={<LandingPage />} path="/" />
          <Route element={<TermsOfUse />} path="/terms-of-use" />
          <Route element={<PrivacyPolicy />} path="/privacy-policy" />
          <Route element={<SubscriptionPolicy />} path="/subscription-policy" />
          {isSomatic ? (
            <>
              <Route element={<OnboardingSomaticStepper />} path="/onboarding">
                <Route
                  element={<CurrentSomaticStep />}
                  path="/onboarding/:stepIndex"
                />
              </Route>
              <Route element={<SomaticPaywall />} path="/paywall" />
              <Route element={<FinalQuestionsWrapper />}>
                <Route
                  path="/creating-plan"
                  element={<CreatingPlanSomatic />}
                />
                <Route path="/take-step" element={<PreRegistration />} />
                <Route path="/email" element={<EmailSomtic />} />
                <Route path="/name" element={<NameSomatic />} />
                <Route path="/news" element={<Newsletter />} />
                <Route path="/prediction" element={<PredictionSomatic />} />
                <Route path="/confident" element={<Confident />} />
                <Route path="/plan-chart" element={<PlanChartSomatic />} />
              </Route>
              <Route element={<Upsale />} path="/upsale" />
            </>
          ) : (
            <>
              <Route element={<OnboardingStepper />} path="/onboarding">
                <Route
                  element={<CurrentStep />}
                  path="/onboarding/:stepIndex"
                />
              </Route>
              <Route element={<FinalQuestionsWrapper />}>
                <Route path="/creating-plan" element={<CreatingPlan />} />
                <Route path="/email" element={<Email />} />
                <Route path="/name" element={<Name />} />
                <Route path="/plan-chart" element={<PlanChart />} />
              </Route>

              <Route element={<Paywall />} path="/paywall" />
              <Route element={<Upsale />} path="/upsale" />
            </>
          )}

          <Route
            element={<SomethingWentWrong />}
            path="/something-went-wrong"
          />
          <Route element={<RoutesWithEmail />}>
            <Route element={<Paywall />} path="/paywall" />
            <Route element={<UserActivation />} path="/activate" />
            <Route element={<ThankyouPage />} path="/thankyou" />
          </Route>
          <Route element={<ProtectedRoutes />}>
            <Route element={<Profile />} path="/profile" />
            <Route element={<Workouts />} path="/workouts" />
            <Route element={<Workout />} path={`/workouts/:title`} />
            <Route element={<DayExercises />} path="day-exercises/:id" />
            <Route
              element={<SomethingWentWrong />}
              path="/something-went-wrong"
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
