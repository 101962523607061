import styles from "./styles.module.scss";
import { CardCheckbox } from "./svg/CardCheckbox";
import plus from "@assets/img/emoji/plus.png";
import muscle from "@assets/img/emoji/muscle.png";
import heartinfire from "@assets/img/emoji/heartinfire.png";
import { getPlanPricing } from "src/routes/onboarding/shared/components/paywall/components/plan/utils/getPlanDetails";

const ProductMap = {
  SIX_PACK: {
    icon: muscle,
    title: "6-pack Abs Plan",
  },
  KEGEL: {
    icon: heartinfire,
    title: "Better Sex Plan",
  },
};

const CurrencyMap = {
  USD: "$",
  EUR: "€",
  CAD: "$",
  AUD: "$",
  GBP: "£"
};

export const PlanItem = ({ plan, isDiscounted, checked, onSelect }) => {
  const { priceNew, priceOld, currency, discountPercentage } =
    getPlanPricing(plan);

  const mappedProducts = plan?.products.map(
    (productKey) => ProductMap[productKey]
  );

  const isCombinedPlan = mappedProducts.length > 1;

  return (
    <div
      className={
        checked ? `${styles.plan} ${styles.checked}` : `${styles.plan}`
      }
      onClick={onSelect}
    >
      <div className={styles.container}>
        {isDiscounted && discountPercentage && (
          <div className={styles.discountBadge}>{discountPercentage}% OFF</div>
        )}
        <div className={styles.planContent}>
          {isCombinedPlan ? (
            <div className={styles.combinedPlan}>
              {mappedProducts.map((mappedProduct, index) => (
                <>
                  <div key={index} className={styles.titleRow}>
                    <div className={styles.icon}>
                      <img src={mappedProduct.icon} alt={mappedProduct.title} />
                    </div>
                    <p>{mappedProduct.title}</p>
                  </div>
                  {index < mappedProducts.length - 1 && (
                    <img className={styles.plusSign} src={plus} alt="plus" />
                  )}
                </>
              ))}
            </div>
          ) : (
            <div className={styles.titleRow}>
              <div className={styles.icon}>
                <img
                  src={mappedProducts[0]?.icon}
                  alt={mappedProducts[0]?.title}
                />
              </div>
              <p>{mappedProducts[0]?.title}</p>
            </div>
          )}
          <div className={styles.price}>
            <div className={styles.priceText}>
              {isDiscounted && (
                <p className={styles.priceNew}>
                  {CurrencyMap[currency]}
                  {priceNew} per month
                </p>
              )}
              <p
                className={`${styles.priceOld} ${
                  isDiscounted ? styles.isDiscounted : ""
                }`}
              >
                {CurrencyMap[currency]}
                {priceOld} per month
              </p>
            </div>
            <div className={styles.checkbox}>
              <CardCheckbox checked={checked} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
