import { Elements } from "@stripe/react-stripe-js";
import styles from "./styles.module.scss";
import { StripeElementsProvider } from "src/routes/onboarding/shared/components/paywall/components/plan/stripeElementsContext";
import { FeaturesList } from "../featuresList";
import { PlanItem } from "./components/planItem";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { resetActivePlan, setActivePlan } from "src/redux/slices/plans";
import { Spinner } from "@common/spinner";
import { PaymentOptions } from "./components/paymentOptions";
import { useEffect } from "react";
import { useDiscount } from "src/routes/onboarding/shared/components/paywall/DiscountContext";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY
);

export const Plan = ({ idempotencyKey, onSkip }) => {
  const dispatch = useDispatch();
  const { isDiscounted } = useDiscount();
  const { upsalePlans, activePlan, isLoading } = useSelector(
    (state) => state.plans
  );

  useEffect(() => {
    dispatch(resetActivePlan());
    if (!activePlan) {
      const initialPlan = upsalePlans[0];
      dispatch(setActivePlan(initialPlan));
    }
  }, [upsalePlans, dispatch]);

  const options = {
    mode: "subscription",
    currency: activePlan?.currency?.toLowerCase() || "usd",
    amount: activePlan?.priceAmount || 100,
    appearance: {
      variables: {
        borderRadius: "9999px",
        width: "100%",
      },
    },
  };

  return !isLoading ? (
    <StripeElementsProvider>
      <Elements stripe={stripePromise} options={options}>
        <div className={styles.productCards}>
          {upsalePlans.map((plan) => {
            return (
              <PlanItem
                key={plan.id}
                plan={plan}
                isDiscounted={isDiscounted}
                checked={plan.id === activePlan?.id}
                onSelect={() => dispatch(setActivePlan(plan))}
              />
            );
          })}
        </div>
        <FeaturesList />
        <PaymentOptions
          idempotencyKey={idempotencyKey}
          activePlan={activePlan}
          onSkip={onSkip}
        />
      </Elements>
    </StripeElementsProvider>
  ) : (
    <div className={styles.spinner}>
      <Spinner />
    </div>
  );
};
