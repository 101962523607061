import { SOMATIC_KEYS } from "../../../../shared";
import { OptionsWithBackground } from "../../../../shared";
import background from "./bg.png";
import { useNavigate } from "react-router";

const data = [
  { displayValue: "Quite recently" },
  { displayValue: "Within the last month" },
  { displayValue: "A while ago" },
  { displayValue: "I don’t remember" },
];

export const CryingLastTime = ({ onNextStep }) => {
  const handleNextStep = (v) => {
    console.log(v);
    onNextStep?.();
  };
  return (
    <OptionsWithBackground
      data={data}
      title="What was the last time you cried?"
      onClick={handleNextStep}
      background={background}
    />
  );
};
